<template>
  <div class="container-fluid admin-login-container">
    <form class="login-form">
      <div class="login-input-group">
        <label for="username">Kullanıcı Adı</label>
        <input
          type="text"
          id="username"
          placeholder="Kullanıcı Adı"
          autocomplete="off"
          v-model="username"
        />
      </div>
      <div class="login-input-group">
        <label for="sifre">Şifre</label>
        <input
          type="password"
          id="sifre"
          placeholder="Şifre"
          v-model="password"
          autocomplete="off"
        />
      </div>
      <div class="message" v-if="submitted && !result.status"> {{result.message}} </div>
      <div class="login-input-group">
        <div class="login-button" @click="goHomePage()">Ana Sayfa</div>
        <button class="login-button" @click.prevent="loginAdminPanel()">
          Giriş Yap
        </button>
      </div>
    </form>
  </div>
</template>

<script>

export default {
  name: "AdminLogin",
  data() {
    return {
      username: "",
      password: "",
      result:"",
      submitted:false,
    };
  },
 
  methods: {
 
    goHomePage() {
      this.$router.push({ path: "/" }, () => {});
    },
    loginAdminPanel() {
      if (this.username && this.password) {
       this.submitted = true;
        let data = {
          username: this.username,
          password: this.password,
        };
        this.$store.dispatch('loginUser',data).then(()=>{
          this.result = this.$store.state.login.loginResult
          console.log(this.result)
          if(this.result.status){
            this.$router.push({ path: "/admin-panel" }, () => {});
          }
        })
        
      }
    },
  },
};
</script>

<style lang="scss">
.admin-login-container {
  width: 100%;
  height: 100vh;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-form {
    padding: 2rem 3rem;
    background-color: white;
    border: 2px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    .login-input-group {
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      width: 100%;
      &:nth-child(3),
      &:nth-child(4) {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
      }
      label {
        font-size: 1.2rem;
        font-weight: bold;
      }
      input {
        border: none;
        border-bottom: 2px solid #e0e0e0;
        padding: 0.5rem;
        font-size: 1.2rem;
        width: 100%;
        outline: none;
        &:focus {
          outline: none;
          border-bottom: 3px solid orangered;
        }
      }

      .login-button {
        background-color: #e0e0e0;
        border: none;
        padding: 0.5rem;
        outline: none;
        border-radius: 5px;
        margin-top: 2rem;
      }
    }
    .remember {
      display: flex;
      align-items: center;
      label {
        margin-left: 0.5rem;
      }
      input[type="checkbox"] {
        height: 16px;
        width: 16px;
      }
    }
  }
}
</style>
